import {Link, useLocation} from '@remix-run/react'
import {ArrowNarrowRightIcon, KubernetesIcon} from './icons'

const showBanner = true
const kubecon = false
const hideOnPages: string[] = [] // ['/drop-week']

export function BannerAnnouncement() {
  const {pathname} = useLocation()
  if (!showBanner) return null
  if (hideOnPages.includes(pathname)) return null

  if (kubecon) {
    return (
      <div className="border-radix-purple6 from-radix-purple1 to-radix-purple2 text-radix-purple11 z-10 flex items-center justify-center gap-1 border-b bg-linear-to-b py-4 leading-none font-medium">
        <KubernetesIcon className="text-radix-purple12" />
        <div>Visit us at KubeCon booth P33!</div>
      </div>
    )
  }

  return (
    <>
      <Link
        to="/blog/introducing-depot-cache"
        className="border-radix-violet6 from-radix-violet1 to-radix-violet2 text-radix-violet12 z-10 hidden items-center justify-center gap-1 border-b bg-linear-to-b py-4 leading-none font-medium md:flex"
      >
        <div>⚡ Introducing Depot Cache — Up to 20x faster builds for Bazel, Gradle, Turborepo, sccache, and Pants</div>
        <ArrowNarrowRightIcon className="text-radix-violet11 h-4 w-4" />
      </Link>
      <Link
        to="/blog/introducing-depot-cache"
        className="border-radix-violet6 from-radix-violet1 to-radix-violet2 text-radix-violet12 z-10 flex items-center justify-center gap-1 border-b bg-linear-to-b py-4 leading-none font-medium md:hidden"
      >
        <div>⚡ Introducing Depot Cache</div>
        <ArrowNarrowRightIcon className="text-radix-violet11 h-4 w-4" />
      </Link>
    </>
  )
}
